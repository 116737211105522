import { Partner, PartnerStats } from "@types";
import { makeQuery } from "./request";

export const getUserSettings = makeQuery({
  chunks: `
    query GetUserSettings {
      getUserSettings {
        id
        email
        spending
        annualIncome
        savingsPerYear
        monthlyRepayment
        availableLiquidity
        retirementSimulation
        sales
        loanDetails {
          debtRate
          loanCapacity
        }
        investorProfile {
          risk
          experience	
        }
        patrimonialsGoals {
          yield
          investmentType
          investmentHorizon
          investmentCategories
        }
        retirementForm {
          id
          userId
          profession
          best25Year
          dateOfBirth
          endOfActivity
          startOfActivity
          yearsOfInterruption
        }
      }
    }`,
});

export const getPartner = makeQuery<{ partner: Partner }>({
  chunks: `
  query Partner {
  partner {
    id
    name
    nbLeads
    nbLeadsProspected
    discounts {
      id
      code
      amount
      users {
        firstName
        lastName
        email
        sales
        created
      }
    }
    parent {
      id
    }
    children {
      name
      id
      discounts {
        id
        code
        amount
        users {
          id
          name
          isPremium
          firstName
          lastName
          email
          sales
          created
        }
      }
    }
  }}`,
});

export const getPartnerStats = makeQuery<{ partnerStats: PartnerStats }>({
  chunks: `
    query PartnerStats {
      partnerStats {
        nbPremium
        nbFree
        nbUsers
        totalRevenue
      }
    }
  `,
});

export function isArray(x: any): x is [] {
  return Array.isArray(x);
}

export function isStr(x: any): x is string {
  return typeof x === "string";
}

export function isFn(x: any): x is (...args: any) => any {
  return !!x && typeof x === "function";
}

export function isObj(x: any): x is object {
  return Object.prototype.toString.call(x) === "[object Object]";
}

export function isDate(x: any): x is Date {
  return (
    Object.prototype.toString.call(x) === "[object Date]" &&
    x.toString() !== "Invalid Date"
  );
}

export function isBool(x: any): x is boolean {
  return !!x && typeof x == "boolean";
}

export function isEmpty(x: any): x is object | [] | string {
  if (x === null || x === undefined) return false;
  if (isObj(x)) return !Object.keys(x).length;
  if (isArray(x) || isStr(x)) return !x?.length;

  return false;
}

export function isNotEmpty(x: any): boolean {
  return !isEmpty(x);
}

export function isSet(x: any): boolean {
  return (
    x !== null &&
    x !== undefined &&
    x !== "" &&
    !Number.isNaN(x) &&
    x !== Infinity &&
    x !== -Infinity
  );
}

export function isAllSet<T, K>(args: any[] | T, keys?: K[]): boolean {
  let arr: any[] = [];
  if (isEmpty(args)) return false;

  if (isArray(args)) {
    arr = args;
  } else if (isObj(args)) {
    if (keys && !keys.length) return false;
    arr = isArray(keys) ? keys.map((k) => args[k]) : Object.values(args);
  }

  return arr.every(isSet);
}

import { gql, Variables } from "graphql-request";

import { AuthService } from "useCases/authentification";
import { client } from "./client";

const errorHandler = (error: any) => {
  const has401Error =
    error?.response?.status === 401 ||
    error?.response?.errors?.find((e: any) =>
      ["Unauthorized", "Forbidden resource"].includes(e.message)
    );

  if (has401Error) {
    AuthService.localLogout(); // FIXME: Logout and check if it's not authFallback url
  }

  throw error;
};
const request = async <T>(chunks: string, variables?: unknown): Promise<T> => {
  try {
    const graphqlClient = client;

    const req = gql`
      ${chunks}
    `;
    const data = await graphqlClient.request(req, variables);

    return data;
  } catch (error: any) {
    errorHandler(error);
    throw error;
  }
};

export const makeMutation =
  <T>(chunks: string) =>
  async (variables?: any): Promise<T> => {
    return request(chunks, variables);
  };

export const makeQuery = <T>({
  chunks,
  variables,
}: {
  chunks: string;
  variables?: Variables;
}) => {
  return async (): Promise<T> => {
    return request(chunks, variables);
  };
};

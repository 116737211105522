import { Fragment } from "react";
import { clsx } from "clsx";
import { Dialog, Transition } from "@headlessui/react";

import { Icon } from "Component/Icon";

interface ModalProps {
  setOpen: (open: boolean | any) => void;
  open: boolean;
  className?: string;
  children: JSX.Element;
}
export function Modal({ children, setOpen, open, className }: ModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="flex items-center justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                "inline-block align-bottom bg-gray-1200 text-left shadow-xl transform transition-all sm:align-middle sm:my-8 rounded-md p-4 modal-premium w-11/12 sm_md:w-8/12 2xl:max-w-[1300px]",
                className
              )}
            >
              <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
                <Icon
                  type="delete"
                  onClick={() => setOpen(false)}
                  className="w-4 h-4 cursor-pointer"
                />
              </div>
              <div className="text-center h-full w-full sm:mt-0 sm:text-left">
                <div className="flex flex-col h-full w-full rounded">
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/solid";
import { Partner, UserInterface, Amount } from "@types";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { pick } from "helpers";
import { Modal } from "Component/Modal";
import { HomeLogic } from "./home.logic";
import { Button } from "Component/Button";
import { getPartner } from "useCases/query";
import { useListState } from "@mantine/hooks";
import {
  Header,
  Icon,
  Input,
  Loading,
  MultiLevel,
  Table,
  Widget,
} from "Component";
import {
  amountFormatterOptions,
  countFormatter,
  dateFormatter,
  percentFormatter,
} from "localizations";

function leafsUsers(partner: Partner): UserInterface[] {
  if (partner.children && partner.children.length > 0) {
    const childUsers: UserInterface[] = [];
    childUsers.push(
      ...(partner.discounts?.flatMap((discount) => discount.users ?? []) ?? [])
    );
    for (const child of partner.children) {
      childUsers.push(...leafsUsers(child));
    }
    return childUsers;
  } else
    return (
      partner.discounts?.flatMap(
        (discount) =>
          discount.users?.map((user) => {
            return {
              ...user,
              code: discount.code,
              apport: partner.name,
            };
          }) ?? []
      ) ?? []
    );
}

const HeaderInformations = ({
  total,
  premium,
  free,
}: {
  total: number;
  premium: number;
  free: number;
}) => {
  return (
    <div className="flex md:items-center items-start md:gap-x-8 gap-x-0 md:flex-row flex-col">
      <p className="font-semibold text-white">{`Nombre d'inscrits : ${countFormatter.format(
        total
      )}`}</p>
      <div>
        <p className="font-medium text-white">{`Nombre de premium : ${premium}`}</p>
        <p className="font-medium text-white">
          Nombre de non-premium :{" "}
          <span className="text-red">{countFormatter.format(free)}</span>
        </p>
      </div>
    </div>
  );
};

const Leads = ({
  total,
  prospected,
  non_prospected,
  toggleOpen,
}: {
  total: number;
  prospected: number;
  non_prospected: number;
  toggleOpen: () => void;
}) => (
  <div className="relative flex flex-col h-full gap-y-3">
    <PencilIcon
      onClick={toggleOpen}
      className="absolute w-5 -top-2 -right-2 cursor-pointer text-white border border-white rounded-full"
    />
    <p className="text-white font-medium">{`Nombre de leads: ${countFormatter.format(
      total
    )}`}</p>
    <p className="text-white font-medium">{`Leads prospectés: ${countFormatter.format(
      prospected
    )}`}</p>
    <p className="text-white font-medium">{`Leads non-prospectés: ${countFormatter.format(
      non_prospected
    )}`}</p>
  </div>
);

const Rates = ({
  sign,
  premium,
  conversion,
}: {
  sign: number;
  premium: number;
  conversion: number;
}) => (
  <div className="flex flex-col h-full gap-y-3">
    <p className="text-white font-medium">{`Taux de conversion d'inscription: ${percentFormatter.format(
      sign / 100
    )}`}</p>
    <p className="text-white font-medium">{`Taux de conversion Premium: ${percentFormatter.format(
      premium / 100
    )}`}</p>
    <p className="text-white font-medium">{`Taux de conversion: ${percentFormatter.format(
      conversion
    )}`}</p>
  </div>
);

const Commission = ({
  revenu,
  partnersRate,
  date,
}: {
  revenu: Amount;
  partnersRate?: number;
  date?: Date;
}) => {
  const formatter = Intl.NumberFormat(undefined, {
    ...amountFormatterOptions,
    currency: revenu.instrument,
  });

  let upcoming: JSX.Element | null = null;
  if (date !== undefined)
    upcoming = (
      <p className="text-white text-sm">{`le ${dateFormatter.format(date)}`}</p>
    );

  let rate: JSX.Element | null = null;
  if (partnersRate !== undefined)
    rate = (
      <p className="text-white text-sm">{`${percentFormatter.format(
        partnersRate
      )} grâce à mes partenaires`}</p>
    );

  return (
    <div className="flex flex-col h-full md:gap-y-2">
      <p className="text-white font-medium">Commission à venir</p>
      <p className="text-secondary-500 text-xl font-medium">{`${formatter.format(
        revenu.value
      )}`}</p>
      {rate}
      {upcoming}
    </div>
  );
};
const useLeadFormLogic = () => {
  const [open, setOpen] = useState(false);
  const [nbLeads, setNbLeads] = useState(0);
  const [nbLeadsProspected, setNbLeadsProspected] = useState(0);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    "setNbLeadsInfo",
    async (args: HomeLogic.PartnerLeadData) =>
      HomeLogic.setPartnerLeadsData(args),
    {
      onSuccess: () => {
        queryClient.refetchQueries("home");
      },
    }
  );

  const toggleOpen = () => setOpen(!open);

  const onChange =
    (name: keyof HomeLogic.PartnerLeadData) => (value: string) => {
      if (name === "nbLeads") setNbLeads(parseInt(value));
      else if (name === "nbLeadsProspected")
        setNbLeadsProspected(parseInt(value));
    };

  const handleSumbit = () => {
    mutation.mutate({ nbLeads, nbLeadsProspected: nbLeadsProspected });
    setOpen(false);
  };

  return {
    open,
    nbLeads,
    nbLeadsProspected,
    setOpen,
    toggleOpen,
    onChange,
    handleSumbit,
  };
};
const LeadForm = ({
  values,
  handleSumbit,
  onChange,
}: {
  values: HomeLogic.PartnerLeadData;
  onChange: (name: keyof HomeLogic.PartnerLeadData) => (value: string) => void;
  handleSumbit: () => void;
}) => (
  <div>
    <Input
      defaultValue={values.nbLeads}
      label="Nombre de leads"
      type="number"
      name="nbLeads"
      onChange={onChange("nbLeads")}
    />
    <Input
      defaultValue={values.nbLeadsProspected}
      label="Leads prospectés"
      type="number"
      name="nbLeadsProspected"
      onChange={onChange("nbLeadsProspected")}
    />
    <Button label="Valider" type="primary" onClick={handleSumbit} />
  </div>
);

export function HomeScreen() {
  const query = useQuery("home", HomeLogic.query);
  const { data, isLoading } = useQuery("getPartner", getPartner);
  const formLogic = useLeadFormLogic();

  const [filters, setFilters] = useState(false);
  const navigate = useNavigate();

  const [displayedUsers, displayedUsersHandlers] = useListState<UserInterface>(
    []
  );

  const onChange = (level: MultiLevel.Level) => {
    const users = leafsUsers(level);
    for (const user of users) {
      if (level.checked) {
        if (
          displayedUsers.find((displayed) => user.id === displayed.id) == null
        )
          displayedUsersHandlers.prepend(user);
      } else {
        const index = displayedUsers.findIndex(
          (displayed) => displayed.id === user.id
        );
        displayedUsersHandlers.remove(index);
      }
    }
  };

  if (isLoading || query.isLoading) return <Loading />;
  if (query.data?.partnerStats === undefined) return <p>No data fetched</p>;

  const partnerStas = query.data.partnerStats;
  const totalSales = query.data.partnerSales * 0.15;

  let menuItems: JSX.Element | null = null;
  if (data?.partner)
    menuItems = <MultiLevel.List props={data.partner} onChange={onChange} />;

  const toggleVisible = () => setFilters(!filters);

  const leadsWidget = (
    <Leads
      toggleOpen={formLogic.toggleOpen}
      total={partnerStas.nbLeads}
      prospected={partnerStas.nbLeadsProspected}
      non_prospected={partnerStas.nbLeadsNotProspected}
    />
  );
  const rateWidget = (
    <Rates
      sign={partnerStas.subscriptionRate}
      premium={partnerStas.premiumSubscriptionRate}
      conversion={partnerStas.nbUsers / partnerStas.nbLeadsProspected}
    />
  );
  const commisionWidget = (
    <Commission revenu={{ value: totalSales, instrument: "EUR" }} />
  );

  return (
    <div className="flex flex-col h-screen">
      <Header
        children={
          <HeaderInformations
            total={partnerStas.nbUsers}
            premium={partnerStas.nbPremium}
            free={partnerStas.nbFree}
          />
        }
      />
      <Button
        label="Filtrer les partenaires"
        onClick={toggleVisible}
        type="primary"
        className="flex _md:hidden w-11/12 mt-3 mx-auto"
        icon="/svg/adjust.svg"
      />
      <div className="flex _md:hidden mt-5 mx-auto w-11/12 min-w-0">
        <div className="overflow-x-auto flex gap-x-4 ">
          <Widget className="flex-none w-80" children={leadsWidget} />
          <Widget className="flex-none _md:w-1/3 w-80" children={rateWidget} />
          <Widget
            className="flex-none _md:w-1/3 w-80"
            children={commisionWidget}
          />
        </div>
      </div>
      <div className="relative w-full _md:max-w-7xl mx-auto pb-12 flex _md:flex-row px-0 flex-grow overflow-y-hidden _md:overflow-hidden">
        <div
          className={clsx(
            filters === true ? "flex" : "hidden _md:flex",
            "w-11/12 mx-auto inset-x-0 _md:w-3/12 absolute _md:static flex flex-col justify-between h-5/6 _md:h-full mt-5 bg-gradient-to-t from-primary-1100 to-primary-1000 rounded-xl p-4 overflow-y-hidden z-40"
          )}
        >
          <div>{menuItems}</div>
          <Button
            label="+ Ajouter un partenaire"
            onClick={() => navigate("/add-partner")}
            type="primary"
          />
        </div>

        <div className="flex flex-col items-center h-full _md:w-9/12 w-full mx-6 my-5 gap-y-5">
          <div className="hidden _md:flex justify-between w-full gap-x-4 _md:flex-row">
            <Widget className="_md:w-1/3 w-80" children={leadsWidget} />
            <Widget className="_md:w-1/3 w-80" children={rateWidget} />
            <Widget className="_md:w-1/3 w-80" children={commisionWidget} />
          </div>
          <Table people={displayedUsers} />
          {displayedUsers.length === 0 && (
            <div className="w-full flex justify-center p-10 gap-x-4 items-center">
              <Icon type="checkbox_list" className="w-20 h-20" />
              <p className="text-xl font-medium text-primary-300">
                Veuillez sélectionner des partenaires pour afficher leurs
                affiliés.
              </p>
            </div>
          )}
        </div>
      </div>
      <Modal
        setOpen={formLogic.setOpen}
        open={formLogic.open}
        className="bg-white w-1/4 min-w[300px]"
      >
        <LeadForm
          values={pick(data?.partner as HomeLogic.PartnerLeadData, [
            "nbLeads",
            "nbLeadsProspected",
          ])}
          onChange={formLogic.onChange}
          handleSumbit={formLogic.handleSumbit}
        />
      </Modal>
    </div>
  );
}

import {
  signInWithPopup,
  getAuth,
  GoogleAuthProvider,
  signOut,
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { firebaseApp } from "./lib";

export type AuthStrategy = "google";

class _AuthService {
  auth: Auth = getAuth(firebaseApp);

  async authWithCredentials(email: string, password: string) {
    try {
      if (!this.auth) return;
      const result = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const idToken = await result.user?.getIdToken();

      return { user: result.user, idToken };
    } catch (error) {
      throw error;
    }
  }
  async authCreateWithCredentials(email: string, password: string) {
    try {
      if (!this.auth) return;
      const result = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const idToken = await result.user?.getIdToken();

      return { user: result.user, idToken };
    } catch (error) {
      throw error;
    }
  }
  async authWithStrategy(strategy: AuthStrategy) {
    switch (strategy) {
      case "google":
        return await this.loginWithGoogle();
      default:
        return;
    }
  }
  private async loginWithGoogle() {
    try {
      if (!this.auth) return;
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(this.auth, provider);
      // get idToken
      const idToken = await result.user?.getIdToken();

      return { user: result.user, idToken };
    } catch (error) {
      throw error;
    }
  }
  async resetPassword(email: string) {
    try {
      if (!this.auth) return;
      await sendPasswordResetEmail(this.auth, email);
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    await signOut(this.auth);
  }
}

export const firebaseAuthService = new _AuthService();

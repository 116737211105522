import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

const languages = ["fr", "en"];
i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "fr",
    fallbackLng: "fr",
    defaultNS: "translation",

    whitelist: languages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });
export default i18n;

import { ReactSVG } from "react-svg";

interface Props {
  type: string;
  rotate?: number;
  className?: string;
  onClick?: (args?: any) => void;
}
export function Icon({ type, rotate, className, ...props }: Props) {
  return (
    <ReactSVG
      {...props}
      src={`/svg/${type}.svg`}
      beforeInjection={(svg) => {
        if (!svg) return;

        svg.dataset.name = type;
        if (className)
          svg.classList.add(
            ...className
              .trim()
              .split(" ")
              .filter((c) => c)
          );
      }}
      wrapper="span"
      style={{
        ...(rotate !== undefined ? { transform: `rotate(${rotate}deg)` } : {}),
        ...(CSSTransition !== undefined ? { transition: `0.5s` } : {}),
      }}
    />
  );
}

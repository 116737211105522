import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate, RouteProps } from "react-router-dom";

import { RouteType, MenuLinkType, UserInterface } from "@types";
import { AuthService } from "useCases/authentification";
import { isFn } from "helpers";

function getRoutes(routeList: Array<RouteType>, authFallBack: string) {
  return routeList.map(({ auth = true, ...route }, i) => {
    const user = AuthService.getUser();
    const key = `${i}-${route.path}`;

    if (authFallBack !== route.path) {
      if (auth) {
        if (!AuthService.isAuth() || (isFn(auth) && (!user || !auth(user))))
          return (
            <Route
              key={key}
              path="*"
              element={<Navigate to={authFallBack} replace />}
            />
          );
      }
      if (route.children && route.children.length) {
        return (
          <Route
            key={key}
            {...route}
            children={getRoutes(route.children, authFallBack)}
          />
        );
      }
    }

    return <Route key={key} {...(route as RouteProps)} />;
  });
}

export function getMenuLinks(
  user: UserInterface,
  routeList: Array<RouteType>,
  visibility: "always" | "never" | "admin" | "tenant" = "always",
  parent?: MenuLinkType
): Array<MenuLinkType> {
  return routeList
    .filter(({ menuVisibility = "always" }) =>
      isFn(menuVisibility)
        ? menuVisibility(user)
        : menuVisibility.includes(visibility) || menuVisibility === "always"
    )
    .map((item) => ({
      name: item.label,
      icon: item.menuIcon,
      to: item.path,
      menuClassName: item.menuClassName,
      menuDisabled: item.menuDisabled,
      parent,
      children: item.children
        ? getMenuLinks(user, item.children, visibility, item)
        : undefined,
    }));
}

export function Navigation({
  routeList,
  authFallBack,
}: {
  routeList: Array<RouteType>;
  authFallBack: string;
}) {
  return (
    <BrowserRouter>
      <Routes>{getRoutes(routeList, authFallBack)}</Routes>
    </BrowserRouter>
  );
}

import { graphql } from "msw";

import { Partner, Discount } from "@types";

const discounts: Discount[] = [
  {
    id: "1",
    name: "Code promo noel",
    code: "BNPHome",
    amount: -20,
    users: [
      {
        id: "1",
        name: "User 1",
        email: "test@test.test",
        isPremium: true,
        referenceInstrument: "sdfds",
        layoutSettings: "sdfds",
      },
    ],
  },
];

const partner: Partner = {
  id: "1",
  name: "Partner 1",
  nbLeads: 10,
  nbLeadsProspected: 8,
  discounts,
  children: [
    {
      id: "11",
      name: "Partner 11",
      nbLeads: 10,
      nbLeadsProspected: 8,
      parentId: "1",
      children: [
        {
          id: "13",
          name: "Partner 13",
          parentId: "11",
          nbLeads: 10,
          nbLeadsProspected: 8,
          discounts: [
            {
              id: "1",
              name: "Code promo noel",
              code: "BNPHome",
              amount: -20,
              users: [
                {
                  id: "13-1",
                  name: "User 13 - 1",
                  email: "test@test.test",
                  isPremium: true,
                  referenceInstrument: "sdfds",
                  layoutSettings: "sdfds",
                },
                {
                  id: "13-2",
                  name: "User 13 - 2",
                  email: "test@test.test",
                  isPremium: true,
                  referenceInstrument: "sdfds",
                  layoutSettings: "sdfds",
                },
              ],
            },
          ],
        },
        {
          id: "14",
          name: "Partner 14",
          nbLeads: 10,
          nbLeadsProspected: 8,
          parentId: "11",
          children: [
            {
              id: "141",
              name: "Partner 141",
              nbLeads: 10,
              nbLeadsProspected: 8,
              discounts: [
                {
                  id: "1",
                  name: "Code promo noel",
                  code: "BNPHome",
                  amount: -20,
                  users: [
                    {
                      id: "141-1",
                      name: "User 141 - 1",
                      email: "test@test.test",
                      isPremium: true,
                      referenceInstrument: "sdfds",
                      layoutSettings: "sdfds",
                    },
                    {
                      id: "141-2",
                      name: "User 142 - 2",
                      email: "test@test.test",
                      isPremium: true,
                      referenceInstrument: "sdfds",
                      layoutSettings: "sdfds",
                    },
                  ],
                },
              ],
              parentId: "14",
            },
          ],
        },
      ],
    },
    {
      id: "12",
      name: "Partner 12",
      parentId: "1",
      nbLeads: 10,
      nbLeadsProspected: 8,
      children: [
        {
          id: "21",
          name: "Partner 21",
          nbLeads: 10,
          nbLeadsProspected: 8,
          parentId: "12",
          discounts: [
            {
              id: "1",
              name: "Code promo noel",
              code: "BNPHome",
              amount: -20,
              users: [
                {
                  id: "21-1",
                  name: "User 21 - 1",
                  email: "test@test.test",
                  isPremium: true,
                  referenceInstrument: "sdfds",
                  layoutSettings: "sdfds",
                },
                {
                  id: "21-2",
                  name: "User 21 - 2",
                  email: "test@test.test",
                  isPremium: true,
                  referenceInstrument: "sdfds",
                  layoutSettings: "sdfds",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const queries = [
  graphql.query("Discounts", (req, res, ctx) => {
    return res(
      ctx.data({
        discounts,
      })
    );
  }),
  graphql.query("Partner", (req, res, ctx) => {
    return res(
      ctx.data({
        partner,
      })
    );
  }),
];

export default queries;

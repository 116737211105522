import clsx from "clsx";

export interface WidgetProps {
  children: JSX.Element;
  className?: string;
}

export function Widget({ children, className }: WidgetProps) {
  return (
    <div
      className={clsx(
        "bg-gradient-to-t from-primary-1100 to-primary-1000 md:h-40 h-32 rounded-xl p-4",
        className
      )}
    >
      {children}
    </div>
  );
}

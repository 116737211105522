import clsx from "clsx";

interface ButtonProps {
  label?: string;
  icon?: string;
  type?: "primary" | "secondary";
  onClick: any;
  size?: "small" | "big";
  className?: string;
}

export function Button({
  label,
  icon,
  type,
  onClick,
  className,
  size,
}: ButtonProps) {
  let sizeStyle = "px-4 py-2 text-sm";
  let iconStyle = "w-7 h-7";
  let colorStyle =
    "bg-white text-gray-800 border border-gray-1100 hover:bg-slate-50";
  switch (size) {
    case "big":
      sizeStyle = "px-6 py-3 text-base";
      iconStyle = "w-10 h-10";
      break;
    case "small":
      sizeStyle = "px-2.5 py-1.5 text-xs";
      iconStyle = "w-5 h-5";
      break;
  }

  switch (type) {
    case "primary":
      colorStyle =
        "bg-gradient-to-t from-primary-1100 to-primary-1000 hover:bg-gradient-to-t hover:from-secondary-500 hover:to-secondary-400";
      break;
    case "secondary":
      colorStyle = "bg-gradient-to-t from-secondary-500 to-secondary-400";
      break;
  }

  return (
    <button
      type="button"
      className={clsx(
        "w-full inline-flex justify-center items-center rounded font-medium text-white shadow-sm cursor-pointer",
        sizeStyle,
        colorStyle,
        className
      )}
      onClick={onClick}
    >
      {icon && <img src={icon} alt="" className={clsx("mr-1", iconStyle)} />}
      {label && label}
    </button>
  );
}

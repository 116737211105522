import { AddPartnerScreen, HomeScreen, LoginScreen } from "screens";
import { RouteType } from "@types";

import { routes } from "./routes";

export const routeList: Array<RouteType> = [
  // Public Auth
  {
    path: routes.login,
    element: <LoginScreen pageType="login" />,
    auth: false,
  },
  {
    path: routes.forgotPassword,
    element: <LoginScreen pageType="forgot-password" />,
    menuVisibility: "never",
    auth: false,
  },

  // Private Pages
  {
    path: routes.home,
    element: <HomeScreen />,
  },
  {
    path: routes.addPartner,
    element: <AddPartnerScreen />,
  },
];
export const authFallBack = routes.login;

import { useState } from "react";
import { Checkbox } from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { Partner } from "@types";
import { Icon } from "Component";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MultiLevel {
  export interface Level extends Partner {
    checked?: boolean;
    children?: Level[];
  }

  export interface Props {
    props: Level;
    onChange?: (level: Level) => void;
  }

  export function List({ props, onChange }: Props) {
    const levels: Level[] | undefined = props?.children?.map((child) => ({
      ...child,
      checked: props.checked ?? false,
    }));

    // States
    const [values, handlers] = useListState(levels);
    const allChecked = values.every((value) => value.checked);
    const [checked, setChecked] = useState<boolean | undefined>(
      levels === undefined || levels.length === 0 ? false : undefined
    );
    const indeterminate = values.some((value) => value.checked) && !allChecked;
    const [isCollapse, setIsCollapse] = useState(true);

    // Hooks
    const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      handlers.setState((current) =>
        current.map((value) => ({
          ...value,
          checked: !(props.checked ?? allChecked),
        }))
      );
      if (levels === undefined || levels.length === 0)
        setChecked(event.currentTarget.checked);
      if (onChange)
        onChange({ ...props, checked: event.currentTarget.checked });
    };

    const onValueChange = (level: Level, index: number) => {
      handlers.setItemProp(index, "checked", level.checked);
      if (onChange) onChange({ ...level });
    };

    // Layout

    // Collasable menu
    const items: JSX.Element[] | null = values.map((value, index) => {
      return (
        <div key={index} className="ml-10">
          <List
            props={{ ...value, checked: props.checked ?? value.checked }}
            onChange={(level) => onValueChange(level, index)}
          />
        </div>
      );
    });

    // Icon Button
    let button: JSX.Element | null = null;
    if (values.length > 0)
      button = (
        <Icon
          type="wc_arrowSlider"
          className="w-4 h-4 cursor-pointer"
          rotate={isCollapse ? undefined : -90} // Rotate the icon a quater counterclock wise if menu not collapse
          onClick={() => {
            setIsCollapse(!isCollapse);
          }}
        />
      );

    return (
      <div key={props.id}>
        <div className="flex w-full justify-between items-center">
          <Checkbox
            checked={props.checked ?? checked ?? allChecked}
            indeterminate={indeterminate}
            label={props.name}
            transitionDuration={0}
            onChange={onCheckChange}
            sx={{
              "& .mantine-Checkbox-label": {
                color: "white",
              },
            }}
            classNames={{
              label: "cursor-pointer",
            }}
          />
          {button}
        </div>

        {isCollapse ? null : items}
      </div>
    );
  }
}

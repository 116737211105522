interface Options {
  id: string;
  name: string;
}

interface RadioProps {
  label: string;
  description?: string;
  options: Options[];
}

export function Radio({ label, description, options }: RadioProps) {
  return (
    <div>
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <p className="text-xs leading-5 text-gray-500">{description}</p>
      <fieldset className="mt-2 ml-2">
        <legend className="sr-only">{label}</legend>
        <div className="space-y-2">
          {options.map((option) => (
            <div key={option.id} className="flex items-center">
              <input
                id={option.id}
                name="notification-method"
                type="radio"
                defaultChecked={option.id === "email"}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor={option.id}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}

import { graphql } from "msw";

import { AuthResponse } from "@types";

interface AuthWithTokenResponse {
  authWithToken: AuthResponse;
}
const autWithTokenResponse: AuthWithTokenResponse = {
  authWithToken: {
    token: "sdfsdf",
    user: {
      id: "1",
      name: "Test",
      email: "test@test.test",
      isPremium: false,
      referenceInstrument: "sdfds",
      layoutSettings: "sdfds",
    },
  },
};

const mutations = [
  graphql.mutation("AuthWithToken", (req, res, ctx) =>
    res(ctx.data(autWithTokenResponse))
  ),
];

export default mutations;

import { gql } from "graphql-request";
import { client } from "useCases/query/client";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace HomeLogic {
  export type PartnerStats = {
    nbPremium: number;
    nbFree: number;
    nbUsers: number;
    nbLeads: number;
    nbLeadsProspected: number;
    nbLeadsNotProspected: number;
    subscriptionRate: number;
    premiumSubscriptionRate: number;
    freeSubscriptionRate: number;
  };

  export type Response = {
    partnerStats: PartnerStats;
    partnerSales: number;
  };

  export type PartnerLeadData = {
    nbLeads: number;
    nbLeadsProspected: number;
  };

  export async function query(): Promise<Response> {
    const query = gql`
      query Home {
        partnerStats {
          nbPremium
          nbFree
          nbUsers
          nbLeads
          nbLeadsProspected
          nbLeadsNotProspected
          subscriptionRate
          premiumSubscriptionRate
          freeSubscriptionRate
        }
        partnerSales
      }
    `;

    return await client.request<Response>(query);
  }

  export async function setPartnerLeadsData(
    args: PartnerLeadData
  ): Promise<void> {
    const mutation = gql`
      mutation SetPartnerLeadsData($args: PartnerLeadDataInput!) {
        setPartnerLeadsData(args: $args) {
          nbLeads
        }
      }
    `;

    await client.request(mutation, { args });
  }
}

import { AuthResponse, UserInterface } from "@types";
import { FRAGMENT_USER_FIELDS } from "./fragments";
import { makeMutation } from "./request";

export const authWithTokenMutation = makeMutation<{
  authWithToken: AuthResponse;
}>(`
    mutation AuthWithToken($token: String!) {
      authWithToken(token: $token) {
        token
        user {
          ${FRAGMENT_USER_FIELDS}
        }
      }
    }
  `);

export const addNewPartnerForm = makeMutation<{
  addNewPartner: UserInterface;
}>(`
    mutation AddNewPartner($partner: PartnerCreateInput!) {
      createPartner(partner: $partner) {
          id
        }
    }  
`);

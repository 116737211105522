import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addNewPartnerForm } from "useCases/query";

import { Input, Radio } from "Component";
import { Button } from "Component/Button";
import { Header } from "Component/Header";
import { routes } from "useCases";
import { isAllSet } from "helpers";

const accountActive = [
  { id: "oui", name: "Oui" },
  { id: "non", name: "Non" },
];

const useAddPartnerLogic = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [, setConfirmEmail] = useState("");
  const [, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [reversion, setReversion] = useState("15");

  const handleSubmit = async () => {
    try {
      if (isNaN(Number(reversion)))
        return console.error("Reversion is not a number");
      if (!isAllSet({ name, email }))
        return console.error("Name or email is not set");

      const _reversion = parseInt(reversion);

      await addNewPartnerForm({
        partner: {
          info: {
            name,
            website,
            reversion: _reversion,
            address: {
              address,
              street,
              zipCode,
              city,
              state,
              country,
            },
          },
          relatedUserInfo: {
            firstName,
            lastName,
            email,
          },
        },
      });
      navigate(routes.home);
    } catch (error) {}
  };

  return {
    setName,
    setEmail,
    setWebsite,
    setAddress,
    setStreet,
    setZipCode,
    setCity,
    setConfirmEmail,
    setPhone,
    setState,
    setCountry,
    setFirstName,
    setLastName,
    setReversion,
    handleSubmit,
  };
};

export function AddPartnerScreen() {
  const {
    setName,
    setEmail,
    setWebsite,
    setAddress,
    setStreet,
    setZipCode,
    setCity,
    setState,
    setConfirmEmail,
    setPhone,
    setCountry,
    setFirstName,
    setLastName,
    setReversion,
    handleSubmit,
  } = useAddPartnerLogic();
  return (
    <div className="bg-gray-1000 h-full">
      <Header />
      <div className="max-w-7xl mx-auto p-4 pt-6 pb-10">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Création d'un partenaire
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Remplissez ce formulaire afin de créer un nouveau partenaire.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <Input
                    type="text"
                    onChange={setName}
                    name="name"
                    label="Enseigne"
                    required
                  />
                  <Radio label="Compte actif" options={accountActive} />
                  <Input
                    type="number"
                    onChange={setReversion}
                    name="reversion"
                    label="% de reversion"
                    required
                  />
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Nom
                    </label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-x-4">
                      <Input
                        type="text"
                        onChange={setFirstName}
                        name="firstName"
                        smallLabel="Prénom"
                      />
                      <Input
                        type="text"
                        onChange={setLastName}
                        name="lastName"
                        smallLabel="Nom"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Adresse
                    </label>
                    <Input
                      type="text"
                      onChange={setAddress}
                      name="address"
                      smallLabel="Adresse 1"
                    />
                    <Input
                      type="text"
                      onChange={setStreet}
                      name="street"
                      smallLabel="Adresse ligne 2"
                    />
                    <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-x-4">
                      <Input
                        type="text"
                        onChange={setCity}
                        name="city"
                        smallLabel="Ville"
                      />
                      <Input
                        type="text"
                        onChange={setState}
                        name="state"
                        smallLabel="Etat/Province/Région"
                      />
                      <Input
                        type="text"
                        onChange={setZipCode}
                        name="zipCode"
                        smallLabel="Code Postal"
                      />
                      <Input
                        type="text"
                        onChange={setCountry}
                        name="country"
                        smallLabel="Pays"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-x-4">
                      <Input
                        type="email"
                        name="email"
                        onChange={setEmail}
                        label="E-mail"
                        required
                      />
                      <Input
                        type="email"
                        name="confirmEmail"
                        onChange={setConfirmEmail}
                        label="Confirmez l'email"
                        required
                      />
                    </div>
                  </div>
                  <Input
                    type="text"
                    onChange={setPhone}
                    name="phone"
                    label="Téléphone"
                  />
                  <Input
                    type="text"
                    onChange={setWebsite}
                    name="website"
                    label="Site / URL"
                  />
                  <div className="flex w-full">
                    <Button
                      onClick={handleSubmit}
                      label="Ajouter"
                      type="primary"
                      className="w-fit mx-auto"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

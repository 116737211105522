import clsx from "clsx";

interface InputProps {
  label?: string;
  defaultValue?: string | number;
  smallLabel?: string;
  placeholder?: string;
  type: string;
  name: string;
  className?: string;
  required?: boolean;
  onChange: (e: string) => void;
}

export function Input({
  label,
  defaultValue,
  smallLabel,
  placeholder,
  type = "text",
  name,
  className,
  onChange,
  required = false,
}: InputProps) {
  return (
    <div className="flex flex-col mb-2">
      <div className="flex">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        {required && label && <span className="text-red-600 ml-1">*</span>}
      </div>
      <div className="mt-1">
        <input
          type={type}
          name={name}
          defaultValue={defaultValue}
          className={clsx(
            "block w-full h-10 py-2 px-3 border-gray-1100 border rounded-md shadow-sm focus:border-secondary-500 focus:ring-secondary-500 focus:outline-none focus:ring-1 text-sm lg:text-base",
            className
          )}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      {smallLabel && (
        <label
          htmlFor={name}
          className="mt-1 block text-xs font-normal text-gray-500"
        >
          {smallLabel}
        </label>
      )}
    </div>
  );
}

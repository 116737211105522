export const countFormatter = Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  useGrouping: true,
});

export const amountFormatterOptions = {
  style: "currency",
  maximumFractionDigits: 2,
  useGrouping: true,
};

export const percentFormatter = Intl.NumberFormat(undefined, {
  style: "percent",
  maximumFractionDigits: 2,
});

export const dateFormatter = Intl.DateTimeFormat(undefined, {
  dateStyle: "short",
});

type Obj = { [key: string]: any };

export function pick<T extends object, K extends keyof T>(
  object: T,
  keys: K[]
): Pick<T, K> {
  return keys.reduce((obj, key) => {
    if (object && {}.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }

    return obj;
  }, {} as Pick<T, K>);
}

export function unPick<T extends object, K extends keyof T>(
  object: T,
  keys: K[]
) {
  return pick(
    object,
    Object.keys(object).filter((e) => !keys.includes(e as K)) as K[]
  );
}

export function separete<T extends object, K extends keyof T>(
  object: T,
  keys: K[]
): [Pick<T, K>, Obj] {
  return [pick(object, keys), unPick(object, keys)];
}

export function arrayToObject(arr: (string | number)[] = []) {
  return arr?.reduce((a, v) => ({ ...a, [v]: v }), {});
}

export function truncate(str: string, min: number, max: number) {
  if (!str) return str;

  return `${str.substring(0, min)}...${str.substring(
    str.length - max,
    str.length - 1
  )}`;
}

export const FRAGMENT_USER_FIELDS = `
    id
    name
    email
    firstName
    lastName
    location
    phoneNumber
    birthday
    linkedin
    isPremium
    layoutSettings
    referenceInstrument
`;

const fragmentPartner = `
    id
    name
    discounts {
        id
        code
        amount
        users {
            firstName
            lastName
            email
        }
    }
    parent {
        id
    }
`;

export const PARTNER_CHILDREN_INFOS = `
    ${fragmentPartner}
    children {
        id
        name
    }
`;

import { Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";

import { Icon } from "Component/Icon";
import { getPartner } from "useCases/query";
import { Loading } from "Component/Loading";
import { routes } from "useCases/navigation";
import { AuthService } from "useCases/authentification";

interface HeaderProps {
  title?: string;
  children?: JSX.Element;
  button?: JSX.Element;
}

const solutions = [
  {
    name: "Création d'un partenaire",
    href: routes.addPartner,
    icon: "partner_add",
  },
  {
    name: "Profil",
    href: "#",
    icon: "profile",
  },
];

export function Header({ children, button }: HeaderProps) {
  const { data, isLoading } = useQuery("getPartner", getPartner);

  if (isLoading) return <Loading />;

  const discountCode = data?.partner?.discounts?.[0]?.code;

  return (
    <Popover className="h-fit w-full top-0 z-50 flex justify-between items-center p-5 bg-gradient-to-t from-primary-1100 to-primary-1000">
      <Link className="relative" to={routes.home}>
        <img src="./svg/wc_logo_l.svg" alt="" className="w-12 h-12" />
        <span className="absolute bottom-0 font-bold italic text-secondary-500 left-12 md:flex hidden">
          PARTENAIRES
        </span>
      </Link>

      {children && children}
      {button && button}
      <Popover>
        {() => (
          <>
            <Popover.Button className="group inline-flex items-center rounded-full bg-secondary-500 p-2">
              <Icon className="h-6 w-6" type="menu" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute w-screen md:w-1/3 inset-x-0 md:left-auto md:right-0 top-0 z-30 origin-top-right transform p-2 transition"
              >
                <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="px-5 pt-5 pb-6 sm:pb-8">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          src="./svg/wc_logo.svg"
                          alt=""
                          className="w-12 h-12"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <Icon type="close" className="w-6 h-6" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-8">
                      <nav>
                        <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-center rounded-lg p-3 hover:bg-slate-100"
                            >
                              <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-secondary-500 text-white sm:h-12 sm:w-12">
                                <Icon
                                  type={item.icon}
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 text-base font-medium text-gray-900">
                                {item.name}
                              </div>
                            </Link>
                          ))}
                        </div>
                      </nav>
                    </div>
                  </div>
                  {discountCode && (
                    <div className="relative py-6 px-5 bg-gray-1000">
                      <span className="absolute top-0 text-sm -translate-y-1/2">
                        Votre lien de partenaire est:{" "}
                      </span>
                      {`https://app.wealthcome.fr/register?codePartenaire=${discountCode}`}
                    </div>
                  )}
                  <div className="py-6 px-5">
                    <div>
                      <p
                        onClick={AuthService.localLogout}
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-secondary-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-500"
                      >
                        Se déconnecter
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Popover>
  );
}

import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, getApp, initializeApp } from "firebase/app";

import { config } from "config";
import { pick } from "helpers";

const firebaseConfig = pick(config, [
  "apiKey",
  "authDomain",
  "projectId",
  "appId",
]);

function createFirebaseApp(config: FirebaseOptions) {
  try {
    return getApp();
  } catch {
    const initApp = initializeApp(config);

    if (typeof window !== "undefined") {
      if ("measurementId" in firebaseConfig) {
        getAnalytics();
      }
    }
    return initApp;
  }
}

export const firebaseApp = createFirebaseApp(firebaseConfig);
export const database = getFirestore(firebaseApp);

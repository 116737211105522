import { Navigation } from "useCases";
import * as routeList from "useCases/navigation/routeList";
import { Toaster } from "react-hot-toast";

import "./App.css";

export function App() {
  return (
    <div className="App">
      <Toaster position="top-right" />
      <Navigation {...routeList} />
    </div>
  );
}

import { UserInterface } from "@types";
import { Icon } from "Component/Icon";
import { truncate } from "helpers";
import { amountFormatterOptions, dateFormatter } from "localizations";

export function Table({ people }: { people: (any & UserInterface)[] }) {
  // T-277 Fix with adding missing field in UserInterface (removing N/A)
  const formatter = Intl.NumberFormat(undefined, {
    ...amountFormatterOptions,
    currency: "EUR",
  });

  return (
    <div className="w-full shadow-sm ring-1 ring-black ring-opacity-5 rounded-xl overflow-y-auto relative max-h-full h-fit">
      <table
        className="min-w-full border-separate"
        style={{ borderSpacing: 0 }}
      >
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
            >
              ID
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
            >
              Date
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell"
            >
              Nom
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell"
            >
              Email
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              Apporteur
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              Code
            </th>
            <th
              scope="col"
              className="sticky w-20 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
            >
              Premium
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 text-right text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
            >
              CA
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {people.map((person) => {
            const created = dateFormatter.format(new Date(person.created));

            return (
              <tr key={person.id}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                  {truncate(person.id, 4, 4)}
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Date</dt>
                    <dd className="mt-1 truncate text-gray-700">
                      {created ?? "N/A"}
                    </dd>
                    <dt className="sr-only md:hidden">Nom</dt>
                    <dd className="mt-1 truncate text-gray-500 md:hidden">
                      {person.firstName && person.lastName
                        ? person.firstName + " " + person.lastName
                        : person.name}
                    </dd>
                    <dt className="sr-only md:hidden">Email</dt>
                    <dd className="mt-1 truncate text-gray-500 md:hidden">
                      {person.email}
                    </dd>
                    <dt className="sr-only md:hidden">Apporteur</dt>
                    <dd className="mt-1 truncate text-gray-500 md:hidden">
                      {person.apport ?? "N/A"}
                    </dd>
                    <dt className="sr-only md:hidden">Code</dt>
                    <dd className="mt-1 truncate text-gray-500 md:hidden">
                      {person.code ?? "N/A"}
                    </dd>
                  </dl>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {created ?? "N/A"}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                  {person.firstName && person.lastName
                    ? person.firstName + " " + person.lastName
                    : person.name}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                  {person.email}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {person.apport ?? "N/A"}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {person.code ?? "/"}
                </td>
                <td className="px-3 py-4 text-sm text-right text-gray-500">
                  <Icon
                    type={person.isPremium === true ? "true" : "false"}
                    className="w-4 h-4"
                  />
                </td>
                <td className="py-4 pl-1 pr-2 text-right text-sm font-medium sm:pr-2">
                  {formatter.format(person.sales * 0.15)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "Component/Button";
import { Input } from "Component/Inputs/Input";
import { authWithTokenMutation } from "useCases/query";
import { routes } from "useCases";
import { SettingsService } from "useCases/authentification/Settings";
import { firebaseAuthService } from "useCases/authentification/firebase.auth";
import { AuthService } from "useCases/authentification";

interface RedirectProps {
  idToken: string;
}
export type AuthPageType = "login" | "forgot-password";

const useFormLogic = (pageType: AuthPageType) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation(["errors", "translation"]);
  const isLoginPage = pageType === "login";

  const redirectAuthUser = async (
    { idToken }: RedirectProps,
    rememberMe = false
  ) => {
    const { authWithToken } = await authWithTokenMutation({
      token: idToken,
    });
    const user = authWithToken.user;
    const token = authWithToken.token;

    if (!token || !user) return;

    AuthService.setUser(user, !rememberMe);
    AuthService.setToken(token, !rememberMe);
    SettingsService.setSettings(user.layoutSettings || "");
    window.location.href = routes.home;
  };

  const handleAuthWithForm = async ({ rememberMe = true }: any) => {
    try {
      if (isLoginPage) {
        if (!email || !password)
          throw new Error("Veuillez remplir tous les champs");

        const res = await firebaseAuthService.authWithCredentials(
          email,
          password
        );

        if (!res)
          throw new Error("Une erreur est survenue, veuillez réessayer");

        redirectAuthUser(res, rememberMe);
      } else {
        await firebaseAuthService.resetPassword(email);
        navigate(routes.login);
        toast.success(t("firebase.reset-password"));
      }
    } catch (error: any) {
      toast.error(t(`firebase.${error.code}`));
    }
  };

  return {
    error,
    isLoginPage,
    setEmail,
    setPassword,
    handleAuthWithForm,
    setError,
  };
};

interface Props {
  pageType: AuthPageType;
}
export function LoginScreen({ pageType }: Props) {
  const { isLoginPage, setEmail, setPassword, handleAuthWithForm } =
    useFormLogic(pageType);

  return (
    <div className="flex bg-gray-1000 h-screen items-center">
      <Link to={routes.home}>
        <img
          className="w-1/2 hidden cursor-pointer xl_2xl:flex h-screen object-cover lg:max-w-inherit"
          src="./svg/image-login.svg"
          alt="Welcome to Wealthcome"
        />
      </Link>

      <div className="w-full xl_2xl:w-1/2 py-12 md_lg:py-0">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="relative">
            <Link to={routes.home}>
              <img
                className="mx-auto w-[200px] cursor-pointer"
                src="wc_logo.png"
                alt="wealthcome"
              />
              <span className="absolute bottom-5 font-bold italic text-secondary-500 right-_124">
                PRO
              </span>
            </Link>
          </div>
        </div>
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full" />
            </div>
          </div>
        </div>
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white flex flex-col gap-y-4 py-6 px-4 shadow sm:rounded-lg sm:px-6 rounded-md">
            <Input
              label="Adresse email"
              placeholder="exemple@email.com"
              type="email"
              name="email"
              onChange={setEmail}
            />
            {isLoginPage && (
              <Input
                label="Mot de passe"
                placeholder=".................."
                type="password"
                name="password"
                onChange={setPassword}
              />
            )}
            <div className="w-full flex justify-center">
              <Link
                to={routes.forgotPassword}
                className="font-medium text-primary-500 hover:text-secondary-500"
              >
                Mot de passe oublié?
              </Link>
            </div>
            <Button
              label="Se connecter"
              type="primary"
              onClick={handleAuthWithForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
